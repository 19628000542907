@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    body {
        @apply text-base text-neutral
    }
}

@layer components {
    .prose {
        @apply text-base text-neutral
    }

    .prose > p > a {
        @apply font-normal text-base text-neutral
    }
}

/* Google maps style required */
.pac-container {
    z-index: 1300;
    border: 1px solid #f0f0f0;
    background: #ffffff;
    box-shadow: none;
    
}

header .product-compare,
.catalog-item-product .btn-compare-list {
    @apply hidden;
}

summary::-webkit-details-marker { 
    display: none; 
}

#firebaseui_container .firebaseui-container {
    width: 100%;
    max-width: 100%;
}

#firebaseui_container .firebaseui-card-content {
    padding: 0;
}

#firebaseui_container .firebaseui-idp-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;

    @screen md {
        flex-direction: row;
        gap: 14px;
    }
}

#firebaseui_container .firebaseui-list-item {
    width: 100%;
    margin: 0;

    @screen md {
        width: fit-content;
    }
}

#firebaseui_container .firebaseui-list-item .firebaseui-idp-button {
    display: flex;
    gap: 9px;
    align-items: center;
    width: 100%;
    max-width: 100%;
    height: 44px;
    border-radius: 8px;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.17), 0px 0px 3px 0px rgba(0, 0, 0, 0.08);

    @screen md {
        max-width: fit-content;
    }
}

#firebaseui_container .firebaseui-idp-button .firebaseui-idp-text {
    margin: 0;
    padding: 0;
}

#firebaseui_container .firebaseui-list-item .firebaseui-idp-facebook {
    background-color: #1877F2 !important;
    box-shadow: none;
}

#firebaseui_container .firebaseui-idp-google .firebaseui-idp-icon-wrapper:before,
#firebaseui_container .firebaseui-idp-facebook .firebaseui-idp-icon-wrapper:before {
    content: "";
    background-repeat: no-repeat;
    background-position: center;
    display: block;
    width: 20px;
    height: 20px;
}

#firebaseui_container .firebaseui-idp-facebook .firebaseui-idp-icon-wrapper:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cg clip-path='url(%23clip0_3917_10779)'%3E%3Crect width='20' height='20'/%3E%3Cpath d='M20 10C20 4.47717 15.5229 2.09808e-05 10 2.09808e-05C4.47715 2.09808e-05 0 4.47717 0 10C0 14.9913 3.65686 19.1283 8.4375 19.8785V12.8906H5.89844V10H8.4375V7.7969C8.4375 5.29065 9.93043 3.90627 12.2146 3.90627C13.3087 3.90627 14.4531 4.10158 14.4531 4.10158V6.56252H13.1921C11.9499 6.56252 11.5625 7.33336 11.5625 8.12418V10H14.3359L13.8926 12.8906H11.5625V19.8785C16.3431 19.1283 20 14.9913 20 10Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_3917_10779'%3E%3Crect width='20' height='20' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
}

#firebaseui_container .firebaseui-idp-google .firebaseui-idp-icon-wrapper:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Crect width='20' height='20' fill='none'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M19.6 10.2272C19.6 9.51813 19.5364 8.83631 19.4182 8.18176H10V12.0499H15.3818C15.15 13.2999 14.4455 14.359 13.3864 15.0681V17.5772H16.6182C18.5091 15.8363 19.6 13.2727 19.6 10.2272Z' fill='%234285F4'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.99984 19.9999C12.6998 19.9999 14.9635 19.1044 16.618 17.5772L13.3862 15.0681C12.4908 15.6681 11.3453 16.0226 9.99984 16.0226C7.3953 16.0226 5.19075 14.2635 4.40439 11.8999H1.06348V14.4908C2.70893 17.759 6.09075 19.9999 9.99984 19.9999Z' fill='%2334A853'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.40455 11.9001C4.20455 11.3001 4.09091 10.6592 4.09091 10.0001C4.09091 9.34097 4.20455 8.70006 4.40455 8.10006V5.50916H1.06364C0.386364 6.85916 0 8.38643 0 10.0001C0 11.6137 0.386364 13.141 1.06364 14.491L4.40455 11.9001Z' fill='%23FBBC05'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.99984 3.97727C11.468 3.97727 12.7862 4.48182 13.8226 5.47273L16.6908 2.60455C14.9589 0.990909 12.6953 0 9.99984 0C6.09075 0 2.70893 2.24091 1.06348 5.50909L4.40439 8.1C5.19075 5.73636 7.3953 3.97727 9.99984 3.97727Z' fill='%23EA4335'/%3E%3C/svg%3E");
}

#firebaseui_container .firebaseui-idp-facebook .firebaseui-idp-icon-wrapper img,
#firebaseui_container .firebaseui-idp-google .firebaseui-idp-icon-wrapper img {
    display: none;
}

.dot-divider {
    content: '';
    display: inline-block;
    border-radius: 0.375rem;
    height: 0.25rem;
    width: 0.25rem;
    margin: 0 0.3rem;
    background-color: #121212;
    line-height: 1.25rem;
    vertical-align: middle;
}

.slider-clear-psoudo-class::before,
.slider-clear-psoudo-class.slick-next::before,
.slider-clear-psoudo-class.slick-prev::before {
    content: none;
}